import { config } from '@abyss/web/tools/config';
import { useMsal } from '@azure/msal-react';
import { useApi } from '@src/context/Api';
import { isArray, isUndefined, merge, uniq } from 'lodash';
import { concat } from 'lodash/array';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useMemo } from 'react';

/**
 * defaultValues
 *
 * @type {{emailAddress: (string), displayName: (string), hasAccess: (boolean|null), roles: ((string|string)[]|*[]),
 *   hasRoles: (boolean|null), tenantId: string, msalAccount: {}, localAccountId: string, homeAccountId: string}}
 */
export const defaultValues = {
  displayName: config('APP_ENV') === 'local' ? 'Local User' : '',
  emailAddress: config('APP_ENV') === 'local' ? 'user@email.local' : '',
  hasAccess: config('APP_ENV') === 'local' ? true : null,
  hasRoles: config('APP_ENV') === 'local' ? true : null,
  homeAccountId: '',
  localAccountId: '',
  msalAccount: {},
  msid: '',
  roles: config('APP_ENV') === 'local' ? [config('AUTHORIZATION_ROLE'), 'Role.Admin'] : [],
  tenantId: '',
};

/**
 * UserContext
 *
 * @type {React.Context<{emailAddress: string, displayName: string, hasAccess: (boolean|null), roles: (string[]|*[]),
 *   hasRoles: (boolean|null), tenantId: string, msalAccount: {}, localAccountId: string, homeAccountId: string}>}
 */
const UserContext = createContext(defaultValues);

/**
 *
 * @returns {{emailAddress: string, displayName: string, hasAccess: (boolean|null), roles: (string[]|*[]), hasRoles:
 *   (boolean|null), tenantId: string, msalAccount: {}, localAccountId: string, homeAccountId: string}}
 */
export const useUserContext = () => {
  return useContext(UserContext);
};

/**
 * UserProvider
 *
 * Provides the user context to the application.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const UserProvider = (props) => {
  const { children } = props;

  const { useApiQuery } = useApi();
  const [GetUserRoles, { data: delegatedRoles }] = useApiQuery('GetUserRoles');

  const { instance } = useMsal();

  /**
   * hasAccess
   *
   * Determine if the user has access to the application.
   *
   * @type {boolean}
   */
  const hasAccess = useMemo(() => {
    let theAccess = defaultValues.hasAccess;

    if (config('APP_ENV') !== 'local') {
      const activeAccount = instance.getActiveAccount();

      if (activeAccount && activeAccount.idTokenClaims && activeAccount.idTokenClaims.roles) {
        theAccess = activeAccount.idTokenClaims.roles.includes(config('AUTHORIZATION_ROLE'));
      } else {
        theAccess = false;
      }
    }

    return theAccess;
  }, [instance]);

  const userRoles = useMemo(() => {
    let theRoles = defaultValues.roles;
    if (config('APP_ENV') !== 'local') {
      const activeAccount = instance.getActiveAccount();
      theRoles = activeAccount?.idTokenClaims?.roles;
    }
    return theRoles;
  }, [instance]);

  /**
   * roles
   *
   * Retrieve the delegated roles on behalf of the service user from the API and merge them with the user roles
   *
   * @type {[string,string,string,string,string]|[]}
   */
  const roles = useMemo(() => {
    let theRoles = concat([], defaultValues.roles, userRoles);

    if (config('APP_ENV') !== 'local') {
      (async () => {
        if (hasAccess === true && isUndefined(delegatedRoles)) {
          await GetUserRoles();
        } else if (isArray(delegatedRoles?.roles) && hasAccess === true) {
          theRoles = concat([], defaultValues.roles, userRoles, delegatedRoles?.roles);
        }
      })();
    }

    return uniq(theRoles);
  }, [hasAccess, delegatedRoles, userRoles]);

  const hasRoles = useMemo(() => {
    return roles.length > 1;
  }, [roles]);

  /**
   * msid
   *
   * @type {string|*}
   */
  const msid = useMemo(() => {
    let theMsid;

    if (!isUndefined(delegatedRoles) && hasAccess && hasRoles) {
      theMsid = delegatedRoles?.msid;
    }

    return theMsid;
  }, [hasAccess, hasRoles, delegatedRoles]);

  /**
   * account
   *
   * Synchronize the account from IDP with user context.
   *
   * @type {string}
   */
  const account = useMemo(() => {
    let theAccount = {
      displayName: defaultValues.displayName,
      emailAddress: defaultValues.emailAddress,
      homeAccountId: defaultValues.homeAccountId,
      localAccountId: defaultValues.localAccountId,
      msalAccount: defaultValues.msalAccount,
      tenantId: defaultValues.tenantId,
    };

    if (config('APP_ENV') !== 'local') {
      const activeAccount = instance.getActiveAccount();
      theAccount.displayName = activeAccount?.idTokenClaims?.name;
      theAccount.emailAddress = activeAccount?.idTokenClaims?.email;
      theAccount.homeAccountId = activeAccount?.homeAccountId;
      theAccount.localAccountId = activeAccount?.localAccountId;
      theAccount.msalAccount = activeAccount;
      theAccount.tenantId = activeAccount?.tenantId;
    }

    return theAccount;
  }, [instance]);

  /**
   * User
   *
   * The current user data.
   */
  const user = useMemo(() => {
    return merge({}, defaultValues, { hasAccess, hasRoles, msid, roles, ...account });
  }, [account, roles, hasAccess, msid, hasRoles]);

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
