import { NavMenuPrimitives } from '@abyss/web/ui/NavMenu';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { useRoutesContext } from '@src/context/Routes';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';

import { Menu } from './components/Menu';
import { UserMenu } from './components/UserMenu';
import { Styles } from './includes/styles';

/**
 * Navigation
 *
 * Provides the user with a navigation menu.
 *
 * @returns {Element}
 * @constructor
 */
export const Navigation = () => {
  const { currentRoute, currentRoutes } = useRoutesContext();

  /**
   * Determines the currently active navigation menu item, based on the current route.
   */
  useEffect(() => {
    if (!isEmpty(currentRoute)) {
      const navigationItems = document.querySelectorAll(
        '.app-layout-header .abyss-nav-menu-menu, .app-layout-header .abyss-nav-menu-link-root '
      );

      if (!isEmpty(navigationItems)) {
        navigationItems.forEach((navigationItem) => {
          navigationItem?.classList?.remove('active');

          const parentItem = currentRoutes.find((route) => {
            return route?.navigationLabel === navigationItem.innerText;
          });

          if (
            parentItem?.inUrl === true ||
            currentRoute?.path === navigationItem?.pathname ||
            (navigationItem?.pathname !== '/' && currentRoute?.path?.includes(navigationItem?.pathname)) ||
            navigationItem?.innerText === currentRoute?.navigationLabel ||
            navigationItem?.innerText === currentRoute?.parent?.navigationLabel
          ) {
            navigationItem?.classList.add('active');
          }
        });
      }
    }
  }, [currentRoute?.path, currentRoutes]);

  return (
    <ErrorHandler location="src/layouts/default/Header/components/Navigation/Navigation.jsx">
      <Styles>
        <NavMenuPrimitives.Provider positionViewportToTrigger>
          <NavMenuPrimitives.Root position="end" variant="inverted">
            <NavMenuPrimitives.List>
              <Menu items={currentRoutes} />
              <UserMenu />
            </NavMenuPrimitives.List>
            <NavMenuPrimitives.Viewport />
          </NavMenuPrimitives.Root>
        </NavMenuPrimitives.Provider>
      </Styles>
    </ErrorHandler>
  );
};
