import { useCurrentUser } from '@src/features/Users/hooks/useCurrentUser';
import { useCurrentEnvironment } from '@src/hooks/useCurrentEnvironment';
import { isArray, isEmpty, isNull, isObject, isString, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useMemo, useReducer } from 'react';

import { ACTIONS } from './includes/actions';
import { reducer } from './includes/reducer';

/**
 * Default visibility context values.
 *
 * @type {{visibility: {routes: {}, components: {}}, handleVisibilityComponent:
 *   defaultContext.handleVisibilityComponent}}
 */
const defaultContext = {
  handleVisibilityComponent: () => {},
  visibility: {
    components: {},
    routes: {},
  },
};

/**
 * Creates the visibility context.
 *
 * @type {React.Context<{visibility: {routes: {}, components: {}}, handleVisibilityComponent:
 *   defaultContext.handleVisibilityComponent}>}
 */
const VisibilityContext = createContext(defaultContext);

/**
 * Allows components to access the visibility context.
 *
 * @returns {{visibility: {}, setVisibility: defaultContext.setVisibility}}
 */
export const useVisibilityContext = () => {
  return useContext(VisibilityContext);
};

/**
 * VisibilityProvider
 *
 * Handles setting the context as it relates to component visibility.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const VisibilityProvider = (props) => {
  const { children } = props;

  const currentEnvironment = useCurrentEnvironment();
  const { roles: userRoles } = useCurrentUser();

  const [state, dispatch] = useReducer(reducer, {
    visibility: {
      components: {},
      routes: {},
    },
  });

  /**
   * handleLocalStorageSync
   *
   * Set the status of the visibility from user specified value in local storage.
   *
   * @param event
   * @returns {boolean}
   */
  const handleLocalStorageSync = (event = {}) => {
    const accessor = event?.key;

    if (isUndefined(state?.visibility?.components?.[accessor])) {
      return false;
    }

    const value = String(event?.newValue).toLowerCase();

    if (value === 'disabled') {
      if (state?.visibility?.components?.[accessor]?.storeStatus !== 'disabled') {
        dispatch({ payload: { accessor, options: { storeStatus: 'disabled' } }, type: ACTIONS?.UPDATE_COMPONENT });
      }
    } else if (value === 'enabled') {
      if (state?.visibility?.components?.[accessor]?.storeStatus !== 'enabled') {
        dispatch({ payload: { accessor, options: { storeStatus: 'enabled' } }, type: ACTIONS?.UPDATE_COMPONENT });
      }
    } else if (!isNull(state?.visibility?.components?.[accessor]?.storeStatus)) {
      dispatch({ payload: { accessor, options: { storeStatus: null } }, type: ACTIONS?.UPDATE_COMPONENT });
    }

    return true;
  };

  /**
   * Synchronize local storage with component state.
   */
  useEffect(() => {
    if (!isEmpty(state?.visibility?.components)) {
      Object.keys(state?.visibility?.components).forEach((accessor) => {
        const value = localStorage.getItem(accessor);
        if (isNull(state?.visibility?.components?.[accessor]?.storeStatus) && !isNull(value)) {
          handleLocalStorageSync({ initial: true, key: accessor, newValue: value });
        }
      });
    }
  }, [state]);

  /**
   * Synchronize local storage with component state.
   */
  useEffect(() => {
    window?.addEventListener('storage', handleLocalStorageSync);

    return () => {
      window?.removeEventListener('storage', handleLocalStorageSync);
    };
  }, []);

  /**
   * handleEnabledEnvironments
   *
   * Check for enabled environments.
   *
   * @param accessor
   * @param enabledEnvironments
   * @returns {boolean}
   */
  const handleEnabledEnvironments = (accessor = '', enabledEnvironments = []) => {
    if (isUndefined(accessor) || !isString(accessor) || !isArray(enabledEnvironments)) {
      return false;
    }

    if (isEmpty(accessor) || isEmpty(enabledEnvironments)) {
      return false;
    }

    if (enabledEnvironments.includes(currentEnvironment)) {
      if (state?.visibility?.components?.[accessor]?.environmentStatus !== 'enabled') {
        dispatch({ payload: { accessor, options: { environmentStatus: 'enabled' } }, type: ACTIONS?.UPDATE_COMPONENT });
      }
    }

    return true;
  };

  /**
   * handleDisabledEnvironments
   *
   * Check for disabled environments.
   *
   * @param accessor
   * @param disabledEnvironments
   * @returns {boolean}
   */
  const handleDisabledEnvironments = (accessor = '', disabledEnvironments = []) => {
    if (isUndefined(accessor) || !isString(accessor) || !isArray(disabledEnvironments)) {
      return false;
    }

    if (isEmpty(accessor) || isEmpty(disabledEnvironments)) {
      return false;
    }

    if (disabledEnvironments.includes(currentEnvironment)) {
      if (state?.visibility?.components?.[accessor]?.environmentStatus !== 'disabled') {
        dispatch({
          payload: { accessor, options: { environmentStatus: 'disabled' } },
          type: ACTIONS?.UPDATE_COMPONENT,
        });
      }
    }

    return true;
  };

  /**
   * handleEnabledUserRoles
   *
   * Check for enabled user roles.
   *
   * @param accessor
   * @param enabledUserRoles
   * @returns {boolean}
   */
  const handleEnabledUserRoles = (accessor = '', enabledUserRoles = []) => {
    if (isUndefined(accessor) || !isString(accessor) || !isArray(enabledUserRoles)) {
      return false;
    }

    if (isEmpty(accessor) || isEmpty(enabledUserRoles)) {
      return false;
    }

    let isEnabled = false;

    enabledUserRoles.forEach((role) => {
      if (userRoles.includes(role)) {
        isEnabled = true;
      }
    });

    if (isEnabled === true) {
      if (state?.visibility?.components?.[accessor]?.userRoleStatus !== 'enabled') {
        dispatch({ payload: { accessor, options: { userRoleStatus: 'enabled' } }, type: ACTIONS?.UPDATE_COMPONENT });
      }
    } else if (state?.visibility?.components?.[accessor]?.userRoleStatus !== 'disabled') {
      dispatch({ payload: { accessor, options: { userRoleStatus: 'disabled' } }, type: ACTIONS?.UPDATE_COMPONENT });
    }

    return true;
  };

  /**
   * handleDisabledUserRoles
   *
   * Check for disabled user roles.
   *
   * @param accessor
   * @param disabledUserRoles
   * @returns {boolean}
   */
  const handleDisabledUserRoles = (accessor = '', disabledUserRoles = []) => {
    if (isUndefined(accessor) || !isString(accessor) || !isArray(disabledUserRoles)) {
      return false;
    }

    if (isEmpty(accessor) || isEmpty(disabledUserRoles)) {
      return false;
    }

    let isDisabled = false;

    disabledUserRoles.forEach((role) => {
      if (userRoles.includes(role)) {
        isDisabled = true;
      }
    });

    if (isDisabled === true) {
      if (state?.visibility?.components?.[accessor]?.userRoleStatus !== 'disabled') {
        dispatch({ payload: { accessor, options: { userRoleStatus: 'disabled' } }, type: ACTIONS?.UPDATE_COMPONENT });
      }
    }

    return true;
  };

  /**
   * handleVisibilityComponent
   *
   * Handles the visibility of a component based on environments or user roles.
   *
   * @param accessor
   * @param options
   * @returns {boolean}
   */
  const handleVisibilityComponent = (accessor = '', options = {}) => {
    if (isUndefined(accessor) || !isString(accessor) || !isObject(options)) {
      return false;
    }

    if (isEmpty(accessor) || isEmpty(options)) {
      return false;
    }

    const { disabledEnvironments, disabledUserRoles, enabledEnvironments, enabledUserRoles } = options;

    if (!isUndefined(enabledEnvironments)) {
      handleEnabledEnvironments(accessor, enabledEnvironments);
    }

    if (!isUndefined(disabledEnvironments)) {
      handleDisabledEnvironments(accessor, disabledEnvironments);
    }

    if (!isUndefined(enabledUserRoles)) {
      handleEnabledUserRoles(accessor, enabledUserRoles);
    }

    if (!isUndefined(disabledUserRoles)) {
      handleDisabledUserRoles(accessor, disabledUserRoles);
    }

    return true;
  };

  const value = useMemo(() => {
    return {
      handleVisibilityComponent,
      visibility: state?.visibility,
    };
  }, [state]);

  return <VisibilityContext.Provider value={value}>{children}</VisibilityContext.Provider>;
};

VisibilityProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
