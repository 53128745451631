import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Visibility } from '@src/features/Users/components/Visibility';
import { IsEndDatePresent } from '@src/includes/functions';
import { motion } from 'framer-motion';
import { isEmpty, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { WithToolTip } from './components/WithToolTip';

/**
 * Button: Qmcso
 *
 * Button to add a QMCSO address.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Qmcso = (props) => {
  const { additionalAddresses, onClick } = props;

  const [isDisabled, setIsDisabled] = useState(false);

  /**
   * Check if the button should be disabled.
   */
  useEffect(() => {
    if (!isUndefined(additionalAddresses) && !isEmpty(additionalAddresses)) {
      setIsDisabled(IsEndDatePresent(additionalAddresses, 'QMCSO'));
    }
  }, [additionalAddresses]);

  return (
    <ErrorHandler location="src/routes/private/MemberProfile/components/Buttons/Qmcso/Button.jsx">
      <Visibility accessor="QmcsoButton" enabledUserRoles={['State.Type.Qmcso.Write']}>
        <motion.div
          animate="open"
          initial={{ opacity: 0 }}
          variants={{
            closed: { opacity: 0 },
            open: { opacity: 1 },
          }}
        >
          <WithToolTip status={isDisabled}>
            <Button
              aria-haspopup="dialog"
              css={{ marginRight: 'var(--abyss-space-sm)' }}
              isDisabled={isDisabled}
              onClick={onClick}
              variant="solid"
            >
              <IconSymbol css={{ marginRight: 'var(--abyss-space-sm)' }} icon="add_circle" size="18px" /> QMCSO Address
            </Button>
          </WithToolTip>
        </motion.div>
      </Visibility>
    </ErrorHandler>
  );
};

Qmcso.propTypes = {
  additionalAddresses: PropTypes.arrayOf(
    PropTypes.shape({
      endDate: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  onClick: PropTypes.func,
};

Qmcso.defaultProps = {
  additionalAddresses: [],
  onClick: () => {},
};
