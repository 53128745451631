import { useVisibilityContext } from '@src/features/Users/context/Visibility/Visibility';
import { isEmpty, isNil, isNull } from 'lodash';
import PropTypes from 'prop-types';
import React, { useLayoutEffect } from 'react';

/**
 * Visibility
 *
 * Hides or shows children based on the visibility context.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Visibility = (props) => {
  const { accessor, children, disabledEnvironments, disabledUserRoles, enabledEnvironments, enabledUserRoles } = props;

  const { handleVisibilityComponent, visibility: visibilityContext } = useVisibilityContext();

  /**
   * When the visibility status of a component changes, update the visibility context.
   */
  useLayoutEffect(() => {
    if (!isNil(accessor) && !isEmpty(accessor)) {
      handleVisibilityComponent(accessor, {
        disabledEnvironments,
        disabledUserRoles,
        enabledEnvironments,
        enabledUserRoles,
      });
    }
  }, [accessor, enabledEnvironments, disabledEnvironments, enabledUserRoles, disabledUserRoles]);

  if (
    isNull(visibilityContext?.components?.[accessor]?.status) ||
    visibilityContext?.components?.[accessor]?.status === 'disabled'
  ) {
    return <React.Fragment />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

Visibility.propTypes = {
  accessor: PropTypes.string,
  children: PropTypes.node.isRequired,
  disabledEnvironments: PropTypes.arrayOf(PropTypes.string),
  disabledUserRoles: PropTypes.arrayOf(PropTypes.string),
  enabledEnvironments: PropTypes.arrayOf(PropTypes.string),
  enabledUserRoles: PropTypes.arrayOf(PropTypes.string),
};

Visibility.defaultProps = {
  accessor: '',
  disabledEnvironments: [],
  disabledUserRoles: [],
  enabledEnvironments: [],
  enabledUserRoles: [],
};
