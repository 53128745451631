/* eslint-disable import/no-extraneous-dependencies  */
import { config } from '@abyss/web/tools/config';
import { createTheme } from '@abyss/web/tools/theme';
import { AbyssProvider } from '@abyss/web/ui/AbyssProvider';
import { ToastProvider } from '@abyss/web/ui/Toast';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Router } from '@src/components/Router';
import { msalConfig } from '@src/includes/configuration/authentication';
import themeConfiguration from '@src/includes/configuration/theme.json';
import { logger } from '@src/includes/logger';
import { LogUserActivity } from '@src/requests/mutations/LogUserActivity';
import { GlobalStyles as Styles } from '@src/styles/GlobalStyles';
import Head from 'next/head';
import React from 'react';

export const AbyssTheme = createTheme('uhg', themeConfiguration);

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  const accounts = msalInstance.getAllAccounts();

  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  /**
   * Set active account.
   */
  msalInstance.addEventCallback((event) => {
    if (event?.eventType === EventType?.LOGIN_SUCCESS && event?.payload?.account) {
      msalInstance.setActiveAccount(event?.payload?.account);
    }
  });

  msalInstance.addEventCallback((event) => {
    const logEvent = (message, account) => {
      logger.log(message, true, {
        email: account?.idTokenClaims?.preferred_username,
        msid: account?.idTokenClaims?.msid,
        name: account?.idTokenClaims?.name,
      });
    };

    switch (event?.eventType) {
      case EventType?.LOGIN_FAILURE:
        logEvent('[Event] -> Authentication: Login failed.', event?.payload?.account);
        break;
      case EventType?.LOGIN_START:
        logger.log('[Event] -> Authentication: Login started.', true);
        break;
      case EventType?.LOGIN_SUCCESS:
        logEvent('[Event] -> Authentication: Login succeeded.', event?.payload?.account);
        LogUserActivity([
          'LogUserActivity',
          {
            msId: event?.payload?.account?.idTokenClaims?.msid,
            name: event?.payload?.account?.idTokenClaims?.name,
          },
        ]);
        break;
      case EventType?.LOGOUT_END:
        logEvent('[Event] -> Authentication: Logout ended.', event?.payload?.account);
        break;
      case EventType?.LOGOUT_FAILURE:
        logEvent('[Event] -> Authentication: Logout failed.', event?.payload?.account);
        break;
      case EventType?.LOGOUT_START:
        logEvent('[Event] -> Authentication: Logout started.', event?.payload?.account);
        break;
      case EventType?.LOGOUT_SUCCESS:
        logEvent('[Event] -> Authentication: Logout succeeded.', event?.payload?.account);
        break;
      default:
        break;
    }
  });
});

/**
 * client
 *
 * The main file responsible to handle global application logic.
 *
 * @returns {JSX.Element}
 */
export const client = () => {
  return (
    <ErrorHandler location="src/client.jsx">
      <MsalProvider instance={msalInstance}>
        <AbyssProvider theme={AbyssTheme}>
          <Styles theme={AbyssTheme}>
            <ToastProvider />
            <Head>
              <title>
                {config('APP_ENV') !== 'prod' ? `${config('APP_NAME')} | ${config('APP_ENV')}` : config('APP_NAME')}
              </title>
            </Head>
            <Router />
          </Styles>
        </AbyssProvider>
      </MsalProvider>
    </ErrorHandler>
  );
};
