import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.abyss-nav-menu-container': {
    minWidth: '400px',
  },
  '.abyss-nav-menu-list': {
    '.abyss-nav-menu-link-root': {
      '.abyss-icon': {
        color: 'var(--abyss-colors-foreground)',
        fill: 'var(--abyss-colors-foreground)',
      },
      '.abyss-text-root': {
        color: 'var(--abyss-colors-foreground)',
      },
      '& :hover': {
        backgroundColor: 'var(--abyss-colors-background)',
        borderRadius: '4px',
      },
      '&.active': {
        '.abyss-icon': {
          color: 'var(--abyss-colors-interactive1)',
          fill: 'var(--abyss-colors-interactive1)',
        },
        '.abyss-text-root': {
          color: 'var(--abyss-colors-interactive1)',
        },
      },
    },
    '.abyss-nav-menu-menu': {
      '.abyss-icon': {
        color: 'var(--abyss-colors-foreground)',
        fill: 'var(--abyss-colors-foreground)',
      },
      '.abyss-text-root': {
        color: 'var(--abyss-colors-foreground)',
      },
      '& :hover, & button[data-state="open"]': {
        backgroundColor: 'var(--abyss-colors-background)',
        borderRadius: '4px',
      },
      '&.active': {
        '.abyss-icon': {
          color: 'var(--abyss-colors-interactive1) !important',
          fill: 'var(--abyss-colors-interactive1)',
        },
        '.abyss-text-root': {
          color: 'var(--abyss-colors-interactive1)',
        },
      },
    },
    li: {
      '.abyss-text-root': {
        fontSize: 'var(--abyss-fontSizes-sm)',
        fontWeight: 'var(--abyss-fontWeights-medium)',
      },
      '.nav-icon-wrap': {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
      },
      // second to last item
      '&&:nth-last-child(2)': {
        borderRight: '1px solid var(--abyss-colors-gray3)',
      },
    },
  },
  '.abyss-nav-menu-scroll-button-left, .abyss-nav-menu-scroll-button-right': {
    display: 'none',
  },
  '.abyss-page-header-logo': {
    '&:hover': {
      textDecoration: 'none',
    },
    flex: 'initial',
  },
  '.abyss-page-header-vertical-rule': {
    height: '40px',
  },
});
