import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.abyss-nav-menu-columns-root': {
    padding: 'var(--abyss-space-md)',
  },
  '.abyss-nav-menu-item-description': {
    display: 'none',
  },
  '.abyss-nav-menu-item-title': {
    color: 'var(--abyss-colors-foreground)',
    fontSize: 'var(--abyss-fontSizes-sm)',
    lineHeight: '1',
    marginBottom: '0',
  },
  '.abyss-nav-menu-link': {
    minHeight: 'initial',
    padding: 'var(--abyss-space-sm)',
  },
  '.abyss-nav-menu-link-root': {
    display: 'block',
  },
  '.abyss-nav-menu-menu': {
    '.abyss-text-root': {
      color: 'var(--abyss-colors-foreground)',
      fontSize: 'var(--abyss-fontSizes-sm)',
      lineHeight: '1',
      marginBottom: 0,
      padding: 0,
    },
    minHeight: 'initial',
    padding: 'var(--abyss-space-sm) calc(var(--abyss-space-sm) * 2)',
  },
  '.active .abyss-nav-menu-item-title': {
    color: 'var(--abyss-colors-interactive1)',
  },
});
