/* eslint-disable react/prop-types */
import { config } from '@abyss/web/tools/config';
import { FloatingSection } from '@abyss/web/ui/FloatingSection';
import { PageHeaderPrimitives } from '@abyss/web/ui/PageHeader';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Navigation } from '@src/layouts/default/Header/components/Navigation';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React from 'react';

import { Styles } from './includes/styles';

/**
 * Header
 *
 * Renders the logo and navigation which "sticks" as the user scrolls vertically.
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const Header = (props) => {
  const { stickyRef } = props;

  return (
    <ErrorHandler location="src/layouts/default/Header/Header.jsx">
      <FloatingSection
        alwaysFloat={false}
        containerRef={stickyRef}
        css={{
          'abyss-floating-section-root': {
            '.abyss-drawer-menu-container': {
              backgroundColor: 'var(--abyss-colors-gray1)',
            },
            '&[class*="disableFloat-true"]': {
              '.abyss-page-header-container': {
                '.abyss-page-header-vertical-rule': {
                  height: '30px',
                },
                height: '88px !important',
              },
              top: '40px',
            },
            boxShadow: 'none',
            top: '0px',
            zIndex: '999',
          },
        }}
        position="top"
        space={40}
      >
        <Styles>
          <div id="app-header">
            <motion.div
              animate="open"
              initial={{ opacity: 0 }}
              variants={{
                closed: { opacity: 0 },
                open: { opacity: 1 },
              }}
            >
              <PageHeaderPrimitives.Container className="app-layout-header" fullWidth>
                <PageHeaderPrimitives.Brandmark logoRedirect="/" logoTitle={config('APP_NAME')} />
                <Navigation />
              </PageHeaderPrimitives.Container>
            </motion.div>
          </div>
        </Styles>
      </FloatingSection>
    </ErrorHandler>
  );
};

Header.propTypes = {
  stickyRef: PropTypes.shape({ current: PropTypes.shape({}) }),
};

Header.defaultProps = {
  stickyRef: { current: null },
};
