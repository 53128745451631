import { DateInput } from '@abyss/web/ui/DateInput';
import { Layout } from '@abyss/web/ui/Layout';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Visibility } from '@src/features/Users/components/Visibility';
import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Styles } from './includes/styles';

/**
 * DateRange
 *
 * Displays a date range input.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const DateRange = (props = {}) => {
  const {
    defaultEndDate,
    defaultStartDate,
    endDateLabel,
    isClearable,
    isDisabled,
    isRequired,
    isStartDisabled,
    model,
    startDateLabel,
  } = props;

  return (
    <ErrorHandler location="src/components/DateRange/DateRange.jsx">
      <Visibility>
        <Styles>
          <Layout.Group alignItems="bottom" alignLayout="left" grow space={themeConfiguration?.theme?.space?.md}>
            <DateInput
              isClearable={isClearable}
              isDisabled={isDisabled || isStartDisabled}
              isRequired={isRequired}
              label={!isUndefined(startDateLabel) ? startDateLabel : 'Date Range'}
              maximumDate={defaultEndDate}
              model={!isUndefined(model) ? `${model}.start` : 'dateRange.start'}
              subText=""
              validators={{ required: isRequired }}
            />
            <DateInput
              hideLabel={!!isUndefined(endDateLabel)}
              isClearable={isClearable}
              isDisabled={isDisabled}
              isRequired={isRequired}
              label={!isUndefined(endDateLabel) ? endDateLabel : ''}
              minimumDate={defaultStartDate}
              model={!isUndefined(model) ? `${model}.end` : 'dateRange.end'}
              subText=""
              validators={{ required: true }}
            />
          </Layout.Group>
        </Styles>
      </Visibility>
    </ErrorHandler>
  );
};

DateRange.propTypes = {
  defaultEndDate: PropTypes.string,
  defaultStartDate: PropTypes.string,
  endDateLabel: PropTypes.string,
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  isStartDisabled: PropTypes.bool,
  model: PropTypes.string,
  startDateLabel: PropTypes.string,
};

DateRange.defaultProps = {
  defaultEndDate: '',
  defaultStartDate: '',
  endDateLabel: '',
  isClearable: false,
  isDisabled: false,
  isRequired: false,
  isStartDisabled: false,
  model: '',
  startDateLabel: '',
};
