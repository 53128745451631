import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '#app-header': {
    '.abyss-page-header-container': {
      '.abyss-page-header-vertical-rule': {
        height: '30px',
      },
      height: '52px',
    },
    backgroundColor: '#ffffff',
    top: 0,
    zIndex: '999',
  },
});
