import { config } from '@abyss/web/tools/config';
import { Axios } from '@src/context/Api/includes/Axios';
import { logger } from '@src/includes/logger';

/**
 * LogUserActivity
 *
 * Makes a request to the remote API to log user activity to the database
 *
 * @param payload
 * @returns {Promise<{}>}
 * @constructor
 */
export const LogUserActivity = async (payload) => {
  try {
    const theMutationKey = payload?.[0];
    const thePayload = payload?.[1];

    const requestArgs = {
      baseURL: config('API_URL'),
      data: thePayload,
      headers: {
        'x-api-endpoint': '/loginlog',
      },
      method: 'POST',
      params: { mutationKey: theMutationKey },
    };

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse?.data;
  } catch (error) {
    logger.error('src/requests/mutations/LogUserActivity.jsx.js -> LogUserActivity()', false, error);
  }
};
